/**
 * Carousel Project funcionality
 */
$(function(){
    $('[data-link]').click(function(e) {
        e.preventDefault();
        $(this).parent().addClass('active');
        $(this).parent().siblings().removeClass('active');
    });

    var owl_projects = $('#projects-carousel');

    owl_projects.owlCarousel({
        loop:false,
        autoplay: false,
        autoplayTimeout:2000,
        autoplayHoverPause:true,
        paginationSpeed: 2500,
        margin:10,
        responsiveClass:true,
        items: 3,
        smartSpeed: 1000,
        responsive: {
            300: {
                items: 1,
                autoplay: false
            },
            500: {
                items: 1,
                autoplay: false
            },
            800: {
                items: 2,
                autoplay: false
            },
            992: {
                items: 3
            } 
        }
    });


    $('.projects-item').on('click', function(event){

        var dataInfo = $(this).attr('data-info');

        if($(this).hasClass('active')){
            $(this).removeClass('active');
            $(this).find('.fa').removeClass('fa-angle-up')
            $(this).find('.fa').addClass('fa-angle-down')

            $('#collapse'+dataInfo.toUpperCase()).removeClass('show')

        } else{
            $('.projects-item').removeClass('active');
            $(this).addClass('active');
            $(this).find('.fa').removeClass('fa-angle-down')
            $(this).find('.fa').addClass('fa-angle-up')

            $('.projects-info').removeClass('show');
            $('#collapse'+dataInfo.toUpperCase()).addClass('show')
        }

    });
    

});

$(function() {
    // $('#researchInfo').modal('handleUpdate')
    $('#researchInfo').on('shown.bs.modal', function (e) {
        $('#researchInfo').modal('handleUpdate')
        $('.loader').css({'display':'none'})
    });

    $('#observatoryInfo').on('shown.bs.modal', function (e) {
        $('#observatoryInfo').modal('handleUpdate')
        $('.loader').css({'display':'none'})
    });

    $('#initiativesInfo').on('shown.bs.modal', function (e) {
        $('#initiativesInfo').modal('handleUpdate')
        $('.loader').css({'display':'none'})
    });

    $('#empowermentInfo').on('shown.bs.modal', function (e) {
        $('#empowermentInfo').modal('handleUpdate')
        $('.loader').css({'display':'none'})
    });

});
